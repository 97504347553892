<template>
  <!-- Version: 1.7 -->
  <h2>1. Opis utakmice</h2>
  <p>
    Utakmica Highlight Games Soccerbet je jedinstvena softverska simulacija nasumično odabranih najzanimljivijih
    nogometnih trenutaka s utakmica za stvaranje šansi za igranje.
  </p>
  <h2>2. Opis i duljina utakmice</h2>
  <p>
    „Jedan krug igre elektroničkog klađenja traje između 3 i 5 minuta. Sastoji se od najave utakmica, preambule, prikaza
    u igri i rezultata. Prikaz u igri trajat će 1 minutu.
  </p>
  <p>
    Izbor najzanimljivijih nogometnih događaja statistički je neovisan, nasumičan i nepredvidiv, a generira ga Generator
    slučajnih brojeva – RNG.
  </p>
  <h2>3. Tržišta klađenja</h2>
  <h3>3.1 Točan broj golova</h3>
  <p>
    Klađenje na točan broj golova znači da se u jednoj utakmici (single) mora postići točan broj golova. Dakle, ova
    varijacija klađenja znači da trebate predvidjeti konkretan broj golova unatoč konačnom ishodu cijele utakmice.
  </p>
  <h3>3.2 Točan broj golova domaćina</h3>
  <p>Oklada na točan broj golova koje će domaća momčad postići na utakmici.</p>
  <h3>3.3 Točan broj golova gosta</h3>
  <p>Oklada na točan broj golova koje će gostujuća momčad postići na utakmici.</p>
  <h3>3.4 Obje ekipe daju gol</h3>
  <p>
    Da bi dobili okladu na BTTS (obje ekipe daju gol), obje ekipe moraju postići barem po jedan gol, čineći bilo koji
    rezultat od 1-1 i više dobitnom okladom. Oklada „Obje ekipe daju gol” daje kladioničarima priliku da iskoriste takve
    događaje kako bi dobili profitabilne koeficijente, kada je samo jedna od dvije momčadi uspjela postići gol.
  </p>
  <h3>3.5 Točan rezultat</h3>
  <p>
    Klađenje na točan rezultat je klađenje na ono što mislite da će biti konačni ishod nogometne utakmice, pri čemu s
    maksimalno 6 golova na utakmici postoji 28 mogućih ishoda.
  </p>
  <h3>3.6 Dvoznak</h3>
  <p>
    Oklada na dvoznak omogućuje vam da jednom okladom pokrijete dva od tri moguća ishoda nogometne utakmice. Domaćin i
    neriješeno - Vaša oklada je dobitna ako domaćin pobijedi ili rezultat na utakmici bude neriješen. Gost i neriješeno
    - Vaša oklada je dobitna ako gost pobijedi ili rezultat na utakmici bude neriješen.
  </p>
  <h3>3.7 Rezultat utakmice (1X2)</h3>
  <p>Oklada na pobjedu domaćina (1), pobjedu gosta (2) ili utakmicu koja je završila neriješenim rezultatom (X).</p>
  <h3>3.8 Rezultat utakmice i obje ekipe daju gol (1X2 + BTTS)</h3>
  <p>
    Oklada na rezultat utakmice (1 je pobjeda domaćina, X je neriješeno, 2 je pobjeda gosta) i hoće li obje momčadi
    postići pogodak ili ne.
  </p>
  <ul>
    <li>1 + Gol - Domaćin će pobijediti i obje momčadi postići po barem jedan gol [npr. 3-1].</li>
    <li>1 + Bez gola - Domaćin će pobijediti i jedini će on postići gol [npr. 1-0].</li>
    <li>
      X + Gol - Kladite se na neriješen rezultat i da će obje ekipe momčadi postići po barem jedan gol. [npr. 1-1, 2-2].
    </li>
    <li>X + Bez gola – Kladite se na neriješen rezultat bez postignutih golova ijedne momčadi. [Samo 0-0].</li>
    <li>2 + Gol - Gost će pobijediti i obje momčadi postići po barem jedan gol [npr. 1-2].</li>
    <li>2 + Bez gola - Gost će pobijediti i jedini će on postići gol [npr. 0-1, 0-2].</li>
  </ul>
  <h3>3.9 Rezultat utakmice i više/manje 1,5 golova</h3>
  <p>Oklada na rezultat utakmice od 1X2 i ukupan broj golova manji ili veći od 1,5 gola.</p>
  <h3>3.10 Rezultat utakmice i više/manje 2,5 golova</h3>
  <p>Oklada na rezultat utakmice od 1X2 i ukupan broj golova manji ili veći od 2,5 gola.</p>
  <h3>3.11 Ukupan broj golova (1 do 2, 1 do 3, 1 do 4, 2 do 3, 2 do 4)</h3>
  <p>
    Oklada da će konačan rezultat na utakmici biti 1 do 2, 1 do 3, 1 do 4, 2 do 3, 2 do 4 ukupno golova. Ovo je oklada
    DA ili NE.
  </p>
  <h3>3.12 Više/Manje od 1.5 broj golova domaćina</h3>
  <p>Oklada da će domaća momčad postići više ili manje od 1,5 gola na utakmici.</p>
  <h3>3.13 Više/Manje 1.5 broj golova gosta</h3>
  <p>Oklada da će gostujuća momčad postići više ili manje od 1,5 gola na utakmici.</p>
  <h3>3.14 Više/manje 1.5, 2.5, 3.5, 4.5 Ukupan broj golova</h3>
  <p>
    Oklada da će ukupan broj golova koje su obje momčadi postigle biti manje ili više od 1.5, 2.5, 3.5 ili 4.5 na
    utakmici.
  </p>
</template>
